import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pdfjs, Document, Page } from 'react-pdf';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Flex, Grid, Typography } from 'antd';

import themeToken from '@lib/theme/tokens/index';
import { StyledDrawer } from '@/features/captable/components/Shared/StyledDrawer';
import CloseIcon from '@/components/Icons/CloseIcon';
import { usePermissionsStore } from '@/stores/Permissions';
import { useSelectedCompanyStore } from '@/stores/selectedCompany';

import { useGenerateFileUrl } from '../api/generateFileUrl';
import FileShare from './FileShare';
import FileDownload from './DownloadFile';
import FileDelete from './FileDelete';
import FilesIcons from './FilesIcons';
import { useCreateAnalytics } from '../api/createAnalytics';
import EditFile from './EditFile';
import type { FolderData } from '../types';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const { Text } = Typography;
const { useBreakpoint } = Grid;

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const BodyWrapper = styled('div')`
  background: ${themeToken['branding-natural-5']};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  open: boolean;
  previewOpenDate: Date | undefined;
  isFileDownloading: boolean;
  setOpen: (open: boolean) => void;
  file: FolderData;
  uuid?: string;
  handleDownloadStart: () => void;
  handleDownloadEnd: () => void;
  setIsShareFileOpen: () => void;
  setIsDeleteModalOpen: () => void;
  setIsEditModalOpen: () => void;
  setPreviewOpenDate: Dispatch<SetStateAction<Date | undefined>>;
};

const FilePreview = ({
  open = false,
  previewOpenDate,
  setOpen,
  file,
  uuid,
  isFileDownloading,
  handleDownloadStart,
  handleDownloadEnd,
  setIsShareFileOpen,
  setIsDeleteModalOpen,
  setIsEditModalOpen,
  setPreviewOpenDate,
}: Props) => {
  const { t, i18n } = useTranslation('dataroom');
  dayjs.locale(i18n.language);
  const { permissions } = usePermissionsStore((state) => state);
  const { selectedCompany } = useSelectedCompanyStore((state) => state);

  const screens = useBreakpoint();
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  const [fileExtension, setFileExtension] = useState('');
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const { data } = useGenerateFileUrl({
    company_id: selectedCompany?.cid as number,
    file_id: file.id,
    uuid,
  });

  const { mutate: createAnalyticsMutation } = useCreateAnalytics();

  const handleClose = () => {
    if (previewOpenDate) {
      createAnalyticsMutation({
        company_id: selectedCompany?.cid as number,
        payload: {
          hash: uuid,
          file_id: file.id,
          duration: (new Date().getTime() - previewOpenDate.getTime()) / 1000,
          downloaded: 0,
          viewed: 1,
        },
      });
    }

    setOpen(false);
  };

  useEffect(() => {
    if (data) {
      setFileExtension(data?.data.file_extension);
    }
  }, [data]);

  useEffect(() => {
    if (setPreviewOpenDate && open) setPreviewOpenDate(new Date());
  }, [setPreviewOpenDate, open]);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler once to set the initial size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [pdfPages, setPdfPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPdfPages(numPages);
  };

  const docOptions = useMemo(
    () => ({
      cMapUrl: '/cmaps/',
      standardFontDataUrl: '/standard_fonts/',
    }),
    [],
  );

  const goToNextPage = () => setPageNumber((prev) => Math.min(prev + 1, pdfPages || prev));
  const goToPrevPage = () => setPageNumber((prev) => Math.max(prev - 1, 1));

  const fileUrl = useMemo(() => data?.data.url, [data?.data.url]);
  const pdfPageWidth = useMemo(() => {
    const maxPdfWidth = 800;
    const PdfContainerPadding = 120;

    return !screens.lg
      ? Math.min(windowSize.width, maxPdfWidth) - PdfContainerPadding
      : maxPdfWidth - PdfContainerPadding;
  }, [screens.lg, windowSize.width]);

  return (
    <StyledDrawer
      closable={false}
      className="drawer"
      width={windowSize.width > 768 ? '90%' : '100%'}
      placement="right"
      size="large"
      onClose={handleClose}
      open={open}
    >
      <Flex
        className="drawer-header"
        justify="space-between"
        align="center"
        onContextMenu={(ev) => ev.preventDefault()}
      >
        <Flex align="center" gap={14}>
          <CloseIcon onClick={handleClose} />
          <FilesIcons mimeType={file?.file_mimetype.toLocaleLowerCase()} />
          <Flex vertical>
            <Text
              style={{
                fontSize: themeToken.fontSizeLG,
                color: themeToken['branding-primary-6'],
                fontWeight: 600,
                marginBottom: themeToken.marginXXS,
              }}
            >
              {file?.name}
            </Text>
            <Text
              style={{
                color: themeToken['branding-natural-6'],
                fontSize: themeToken.fontSize,
              }}
            >
              {`${t('fileName')}, ${dayjs(file?.created).format('MMMM D, YYYY, hh:mm A')}`}
            </Text>
          </Flex>
        </Flex>
        <Flex gap={8}>
          {!uuid && (
            <FileShare
              hasLabel={false}
              disabled={!permissions.includes('company.dataroom.management')}
              setIsShareFileOpen={setIsShareFileOpen}
            />
          )}

          <FileDownload
            hasLabel={false}
            fileId={file.id}
            file={file}
            entityType="file"
            uuid={uuid}
            disabled={
              (uuid ? !file.downloadable : !permissions.includes('company.dataroom.download')) || isFileDownloading
            }
            handleDownloadStart={handleDownloadStart}
            handleDownloadEnd={handleDownloadEnd}
          />

          {!uuid && (
            <FileDelete
              hasLabel={false}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              disabled={!permissions.includes('company.dataroom.management')}
            />
          )}

          {!uuid && (
            <EditFile
              hasLabel={false}
              disabled={!permissions.includes('company.dataroom.management')}
              setIsEditModalOpen={setIsEditModalOpen}
            />
          )}
        </Flex>
      </Flex>

      <Flex vertical className="drawer-body" onContextMenu={(ev) => ev.preventDefault()}>
        <BodyWrapper
          style={{
            overflowY: 'scroll',
            position: 'relative',
            width: '100%',
          }}
          onContextMenu={(ev) => ev.preventDefault()}
        >
          {data &&
            (fileExtension === 'pdf' ? (
              <div
                ref={pdfContainerRef}
                style={{
                  position: 'absolute',
                  top: '0',
                }}
              >
                <Document
                  file={fileUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  options={docOptions}
                  onContextMenu={(ev: MouseEvent) => ev.preventDefault()}
                >
                  <Page pageNumber={pageNumber} width={pdfPageWidth} />
                </Document>
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <button disabled={pageNumber <= 1} onClick={goToPrevPage}>
                    Previous
                  </button>
                  <button disabled={pageNumber >= (pdfPages || 1)} onClick={goToNextPage}>
                    Next
                  </button>
                  <p>
                    Page {pageNumber} of {pdfPages}
                  </p>
                </div>
              </div>
            ) : (
              <iframe
                title="file preview"
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${data?.data.url}`}
                width="100%"
                height="100%"
                style={{
                  border: 0,
                }}
                allowFullScreen={true}
              >
                {!fileExtension?.includes('pdf') && (
                  <>
                    This is an embedded
                    <a target="_blank" rel="noreferrer" href="http://office.com">
                      Microsoft Office
                    </a>
                    document, powered by
                    <a target="_blank" rel="noreferrer" href="http://office.com/webapps">
                      Office Online
                    </a>
                  </>
                )}
              </iframe>
            ))}
        </BodyWrapper>
      </Flex>
    </StyledDrawer>
  );
};

export default FilePreview;
